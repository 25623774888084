import React from 'react';

// Own components
import SEO from '../components/seo';
import Layout from '../components/Layout/es';
import { Services } from '../containers/Services';

const ServicesPage = () => (
  <Layout>
    <SEO title="Servicios" />
    <Services />
  </Layout>
);

export default ServicesPage;
